import { HStack, Skeleton, Stack, Text } from '@chakra-ui/react'
import { getTonDecimals } from 'utils/getTonValue'
import { TonIcon } from '../../TonIcon'
import { Amount } from '../../Amount'

export const Ton = ({ isLoaded = false, label, username, value }) => {
  return (
    <Stack spacing="4px">
      {label && (
        <Text
          fontSize="0.9rem"
          color="gray.400"
        >
          {label}
        </Text>
      )}
      {username && (
        <Text
          color="#1398F5"
        >
          {username}
        </Text>
      )}
      {!isLoaded ? (
        <Skeleton
          isLoaded={isLoaded}
          borderRadius="full"
          width="100%"
          height="100%"
          minH="20px"
          minW="120px"
        />
      ) : (
        <HStack>
          <TonIcon fontSize="0.8rem"/>
          <Amount
            fontSize="1.2rem"
            fontWeight="700"
            lineHeight="1"
            decimals={getTonDecimals(value)}
          >
            {value}
          </Amount>
        </HStack>
      )}
    </Stack>
  )
}
