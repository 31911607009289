import { Box, Skeleton, Stack, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { ArrowIcon } from '../../ArrowIcon'

export const Small = (
  {
    to,
    label,
    title,
    isLoaded,
    color = '#1398F5'
  }
) => {
  return (
    <Box
      as={Link}
      to={to}
      position="relative"
      transition="0.3s"
      _hover={{
        transform: 'scale(1.01)'
      }}
      _active={{
        transform: 'scale(0.99)'
      }}
    >
      <Box
        position="absolute"
        top="20px"
        left={0}
        height="48px"
        width="5px"
        borderTopRightRadius="4px"
        borderBottomRightRadius="4px"
        backgroundColor={color}
      />
      <ArrowIcon
        color={color}
        position="absolute"
        top="20px"
        right="20px"
      />
      <Stack
        bg="white"
        borderRadius="12px"
        padding="18px"
        spacing="18px"
        cursor="pointer"
      >
        <Stack spacing="4px">
          <Text
            fontSize="0.9rem"
            color="gray.400"
          >
            {label}
          </Text>
          <Stack spacing="12px">
            {!isLoaded ? (
              <Skeleton
                isLoaded
                borderRadius="full"
                width="100%"
                height="100%"
                minH="20px"
                minW="120px"
              />
            ) : (
              <Text
                color={color}
                fontSize="1.3rem"
                fontWeight="700"
                lineHeight="1"
              >
                {title}
              </Text>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
