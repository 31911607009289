import axios from 'axios'
import { baseURL } from 'constants/baseURL'

const entity = 'pools'

export const pools = Object.freeze({
  async fetch() {
    const response = await axios.get(`${baseURL}/${entity}`)
    return response.data
  },
  async fetchItem(id) {
    const response = await axios.get(`${baseURL}/${entity}/${id}`)
    return response.data
  },
})
