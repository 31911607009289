import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Layout } from 'components/Layout'
import { Text } from '@chakra-ui/react'
import { Authentication } from './components/Authentication'
import { Main } from './entities/Main'
import { Pools } from './entities/Pools'
import { Users } from './entities/Users'
import { StringsProvider } from './components/StringsContext'

function App() {
  return (
    <BrowserRouter>
      <StringsProvider>
        <Layout>
          <Authentication>
            <Routes>
              <Route path="/" element={<Text>admin/</Text>} />
              <Route path="admin/*">
                <Route index element={<Main/>} />
                <Route path="pools/*" element={<Pools/>} />
                <Route path="users/*" element={<Users/>} />
              </Route>
            </Routes>
          </Authentication>
        </Layout>
      </StringsProvider>
    </BrowserRouter>
  );
}

export default App;
