import { Screen } from 'components/Screen'
import { useFetchPoolsItem } from '../../services/useFetchPoolsItem'
import { Snippet } from 'components/Snippet/Snippet'
import { Stack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useStringsContext } from 'components/StringsContext'

export const Nominators = () => {
  const { langKey } = useStringsContext()
  const { id } = useParams()
  const { data } = useFetchPoolsItem(id)

  return (
    <Screen
      title={langKey?.pools_item_private_nominators_item_title}
    >
      <Stack spacing="20px">
        {data?.stakes?.map((item, index) => (
          <Snippet.User
            key={index}
            isLoaded
            to={String(index)}
            label={langKey?.pools_item_private_nominators_item_user_item_stake}
            callout={item?.names[0]}
            amount={item?.stake}
            color={item?.pool?.isPublic ? '#8447FC' : '#1398F5'}
          />
        ))}
      </Stack>
    </Screen>
  )
}
