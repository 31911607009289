import * as Chakra from '@chakra-ui/react'

export const Text = ({ isLoaded = false, label, value }) => {
  return (
    <Chakra.Stack spacing="4px">
      <Chakra.Text
        fontSize="0.9rem"
        color="gray.400"
      >
        {label}
      </Chakra.Text>
      {!isLoaded ? (
        <Chakra.Skeleton
          isLoaded
          borderRadius="full"
          width="100%"
          height="100%"
          minH="20px"
          minW="120px"
        />
      ) : (
        <Chakra.HStack>
          <Chakra.Box
            fontSize="1.2rem"
            fontWeight="700"
            lineHeight="1"
          >
            {value}
          </Chakra.Box>
        </Chakra.HStack>
      )}
    </Chakra.Stack>
  )
}
