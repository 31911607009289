import React from 'react'
import { extendTheme, ChakraProvider } from '@chakra-ui/react'

export const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        //overscrollBehavior: 'none',
        //overflow: 'hidden',
        minWidth: '360px',
        height: '100%',
        width: '100%',
        backgroundColor: '#eff1f3'
      },
    },
  },
  radii: {
    '2xl': '1rem',
    '3xl': '1.5rem',
    base: '0.25rem',
    full: '9999px',
    lg: '0.8rem',
    md: '0.7rem',
    none: '0',
    sm: '0.125rem',
    xl: '0.9rem'
  },
  /*fonts: {
    heading: 'Inter, sans-serif',
    body: 'Inter, sans-serif',
  },*/
  config: {
    initialColorMode: 'light'
  }
})

export const Theme = ({children}) => {
  return (
    <ChakraProvider theme={theme}>
      {children}
    </ChakraProvider>
  )
}
