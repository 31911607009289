import { Route, Routes } from 'react-router-dom'
import { PoolItem } from './PoolItem'
import { Nominators } from './Nominators'
import { NominatorItem } from './NominatorItem'

export const Private = () => {
  return (
    <Routes>
      <Route path=":id/*">
        <Route index element={<PoolItem/>} />
        <Route path="nominators/*">
          <Route index element={<Nominators/>} />
          <Route path=":index/*">
            <Route index element={<NominatorItem/>} />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}
