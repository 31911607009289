import axios from 'axios'
import { baseURL } from 'constants/baseURL'

const entity = 'users'

export const users = Object.freeze({
  async fetch() {
    const response = await axios.get(`${baseURL}/${entity}`)
    return response.data
  },
  async fetchTotals() {
    const response = await axios.get(`${baseURL}/${entity}/totals`)
    return response.data
  }
})
