import { HStack, Skeleton, Stack, Text } from '@chakra-ui/react'
import { Amount as FieldAmount } from '../../Amount'

export const Amount = ({ isLoaded = false, label, value }) => {
  return (
    <Stack spacing="4px">
      <Text
        fontSize="0.9rem"
        color="gray.400"
      >
        {label}
      </Text>
      {!isLoaded ? (
        <Skeleton
          isLoaded
          borderRadius="full"
          width="100%"
          height="100%"
          minH="20px"
          minW="120px"
        />
      ) : (
        <HStack>
          <FieldAmount
            fontSize="1.2rem"
            fontWeight="700"
            lineHeight="1"
            decimals={0}
          >
            {value}
          </FieldAmount>
        </HStack>
      )}
    </Stack>
  )
}
