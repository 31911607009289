import { Screen } from 'components/Screen'
import { useFetchPoolsItem } from '../../services/useFetchPoolsItem'
import { RowsContainer } from 'components/RowsContainer'
import { Snippet } from 'components/Snippet/Snippet'
import { Stack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Row } from 'components/Row'
import moment from 'moment'
import { getDateFromSecond } from 'utils/getDateFromSecond'
import { useStringsContext } from 'components/StringsContext'

export const PoolItem = () => {
  const { id } = useParams()
  const { langKey } = useStringsContext()
  const { data } = useFetchPoolsItem(id)
  const isLoaded = !isEmpty(data)

  const stakeAtDay = moment(getDateFromSecond(data?.pool?.stakeAt)).format('DD.MM.YYYY')
  const stakeAtTime = moment(getDateFromSecond(data?.pool?.stakeAt)).format('HH:mm')

  const color = data?.pool?.isPublic ? '#8447FC' : '#1398F5'

  return (
    <Screen
      title={data?.pool?.title}
    >
      <Stack spacing="20px">
        <Snippet.Small
          isLoaded={isLoaded}
          to={'nominators'}
          label={langKey?.pools_item_private_nominators_count}
          title={data?.pool?.nominatorsCount}
          color={color}
        />
        <RowsContainer color={color}>
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_private_nominators_deposit}
            value={data?.pool?.nominatorsDeposit}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_private_nominators_withdraw}
            value={data?.pool?.nominatorsWithdraw}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_private_nominators_stake}
            value={data?.pool?.nominatorsStake}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_private_nominators_profit}
            value={data?.pool?.nominatorsProfit}
          />
        </RowsContainer>
        <RowsContainer color={color}>
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_private_last_amount}
            value={data?.pool?.lastAmount}
          />
          <Row.Text
            isLoaded={isLoaded}
            label={langKey?.pools_item_private_date}
            value={stakeAtDay}
          />
          <Row.Text
            isLoaded={isLoaded}
            label={langKey?.pools_item_private_time}
            value={stakeAtTime}
          />
        </RowsContainer>
      </Stack>
    </Screen>
  )
}
