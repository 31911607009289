import axios from 'axios'
import { baseURL } from 'constants/baseURL'

const entity = 'botauth'

export const auth = Object.freeze({
  async check() {
    const response = await axios.get(`${baseURL}/${entity}`)
    return response.data
  },
  async login(data) {
    const response = await axios.post(`${baseURL}/${entity}`, data)
    return response.data
  }
})
