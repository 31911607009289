import { Flex, Heading, HStack, SlideFade, Stack, Text } from '@chakra-ui/react'
import { BackButton } from '../BackButton'

export const Screen = (
  { title, subTitle, description, renderFooter, isHideBackButton, children }
) => {
  return (
    <Flex
      flexDirection="column"
      pt="30px"
      px="20px"
      flex={1}
      height="100vh"
    >
      <Stack spacing="10px" mb="24px">
        <HStack spacing="16px" alignItems="center" minH="40px">
          {!isHideBackButton && (
            <BackButton colorScheme="gray"/>
          )}
          <Heading
            fontSize="2rem"
            fontWeight="800"
          >
            {title}
          </Heading>
        </HStack>
        {subTitle && (
          <Text lineHeight="1.2" fontSize="1.2rem" fontWeight="700">{subTitle}</Text>
        )}
        {description && (
          <Text>{description}</Text>
        )}
      </Stack>
      <Flex flexDirection="column" flex={1} pb="80px">
        <SlideFade in style={{ display: 'flex', flex: 1, width: '100%', flexDirection: 'column'}}>
          {children}
        </SlideFade>
      </Flex>
      {renderFooter && (
        <Flex
          position={['sticky', 'static']}
          bottom={0}
          zIndex={10}
          mt={['60px', '40px']}
          pb={['20px', '20px', '60px']}
        >
          {renderFooter}
        </Flex>
      )}
    </Flex>
  )
}
