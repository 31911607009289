import { Screen } from 'components/Screen'
import { Snippet } from 'components/Snippet/Snippet'
import { Stack } from '@chakra-ui/react'
import { useFetchUsers } from '../services/useFetchUsers'
import { useStringsContext } from 'components/StringsContext'

export const UserList = () => {
  const { langKey } = useStringsContext()
  const { data } = useFetchUsers()

  return (
    <Screen
      title={langKey?.users_list_title}
    >
      <Stack spacing="20px">
        {data?.map((item, index) => (
          <Snippet.User
            key={index}
            isLoaded
            to={String(index)}
            label={langKey?.users_list_stake}
            callout={item?.name}
            amount={item?.stake}
          />
        ))}
      </Stack>
    </Screen>
  )
}
