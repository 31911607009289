import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { tgMock } from 'constants/tgMock'

export const useAuthLogin = () => {
  const tgMockData = tgMock.initData
  const tg = window.Telegram?.WebApp
  const host = window.location.hostname

  const initData = host === 'localhost' ? tgMockData : tg?.initData

  const {
    isLoading,
    response,
    request,
  } = useQuery(() => api.auth.login({ initData }))

  return {
    authLogin: request,
    isLoadingAuthLogin: isLoading,
    dataCheckAuth: response
  }
}
