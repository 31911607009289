import useQuery from 'hooks/useQuery'
import { api } from 'api'

export const useAuthCheck = () => {
  const {
    isLoading,
    request,
  } = useQuery(() => api.auth.check())

  return {
    authCheck: request,
    isLoadingAuthCheck: isLoading
  }
}
