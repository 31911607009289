import { Screen } from 'components/Screen'
import { useFetchPoolsItem } from '../../services/useFetchPoolsItem'
import { RowsContainer } from 'components/RowsContainer'
import { Stack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Row } from 'components/Row'
import moment from 'moment/moment'
import { getDateFromSecond } from 'utils/getDateFromSecond'
import { Snippet } from 'components/Snippet'
import { useStringsContext } from 'components/StringsContext'

export const Validator = () => {
  const { langKey } = useStringsContext()
  const { id } = useParams()
  const { data } = useFetchPoolsItem(id)
  const isLoaded = !isEmpty(data)

  const stakeAtDay = moment(getDateFromSecond(data?.pool?.stakeAt)).format('DD.MM.YYYY')
  const stakeAtTime = moment(getDateFromSecond(data?.pool?.stakeAt)).format('HH:mm')

  const color = data?.pool?.isPublic ? '#8447FC' : '#1398F5'

  return (
    <Screen
      title={langKey?.pools_item_public_validator_title}
    >
      <Stack spacing="20px">
        <RowsContainer hideDesign>
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_validator_profit}
            value={data?.validatorProfit}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_validator_clean_profit}
            value={data?.validatorCleanProfit}
          />
        </RowsContainer>
        <RowsContainer hideDesign>
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_validator_last_amount}
            value={data?.pool?.lastAmount}
          />
          <Row.Text
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_validator_date}
            value={stakeAtDay}
          />
          <Row.Text
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_validator_time}
            value={stakeAtTime}
          />
        </RowsContainer>
        {data?.investors?.map((item, index) => (
          <Snippet.Investor
            key={index}
            isLoaded
            to={String(index)}
            label={langKey?.pools_item_public_validator_item_title}
            value={item?.name}
            callout={`${item?.rewardShare * 100}%`}
            color={color}
          />
        ))}
      </Stack>
    </Screen>
  )
}
