import { isEmpty } from 'lodash'

export const getFormatNumber = (value, decimals = 5) => {
  let res
  if (isEmpty(value)) {
    return 0
  }
  if (value?.indexOf(',') !== -1) {
    res = new Intl.NumberFormat('ru-RU', { maximumFractionDigits: decimals }).format(value.replace(',', '.')).replace('.', ',')
  } else {
    res = new Intl.NumberFormat('ru-RU', { maximumFractionDigits: decimals }).format(value).replace(',', '.')
  }
  return res
}
