import { HStack, Image, Text, useToast as useToastChakra } from '@chakra-ui/react'
import { useCallback } from 'react'
import IconSuccess from './icon-success.svg'
import IconError from './icon-error.svg'
import IconWarning from './icon-warning.svg'

export const useToast = () => {
  const _toast = useToastChakra()

  const icon = {
    'success': IconSuccess,
    'error': IconError,
    'warning': IconWarning
  }

  return useCallback(({ title, description, status = 'success' }) => {
    _toast({
      position: 'top',
      render: () => (
        <HStack
          maxW="340px"
          spacing={['12px', '14px']}
          color="white"
          p={['14px', '18px']}
          bg="blackAlpha.800"
          borderRadius="16px"
          lineHeight="1.2"
        >
          <Image src={icon[status]} boxSize="28px"/>
          <Text fontSize="0.98rem" lineHeight="1.4">{description}</Text>
        </HStack>
      ),
    })
  }, [_toast])
}
