import { Screen } from 'components/Screen'
import { useFetchPoolsItem } from '../../services/useFetchPoolsItem'
import { Heading, Stack, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { Row } from 'components/Row'
import { RowsContainer } from 'components/RowsContainer'
import { isEmpty } from 'lodash'
import { useStringsContext } from 'components/StringsContext'

export const NominatorItem = () => {
  const { langKey } = useStringsContext()
  const { id, index } = useParams()
  const { data } = useFetchPoolsItem(id)
  const isLoaded = !isEmpty(data)

  const item = data?.stakes[index]

  return (
    <Screen
      title={langKey?.pools_item_private_nominators_item_user_item_title}
    >
      <Stack spacing="20px">
        <Text
          color="#1398F5"
          fontSize="1.1rem"
          lineHeight="1"
        >
          {item?.names[0]}
        </Text>
        <RowsContainer hideDesign>
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_private_nominators_item_user_item_stake}
            value={item?.stake}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_private_nominators_item_user_item_deposit}
            value={item?.deposit}
          />
        </RowsContainer>
        <Stack spacing="12px">
          <Heading fontSize="1.4rem">
            Revenue
          </Heading>
          <RowsContainer hideDesign>
            <Row.Ton
              isLoaded={isLoaded}
              label={langKey?.pools_item_private_nominators_item_user_item_profit}
              value={item?.profit}
            />
            <Row.Ton
              isLoaded={isLoaded}
              label={langKey?.pools_item_private_nominators_item_user_item_last_profit}
              value={item?.lastProfit}
            />
            <Row.Ton
              isLoaded={isLoaded}
              label={langKey?.pools_item_private_nominators_item_user_item_last_20_profit}
              value={item?.last20Profit}
            />
          </RowsContainer>
        </Stack>
      </Stack>
    </Screen>
  )
}
