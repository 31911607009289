import { Screen } from 'components/Screen'
import { Row } from 'components/Row'
import { RowsContainer } from 'components/RowsContainer'
import { isEmpty } from 'lodash'
import { Heading, Skeleton, Stack } from '@chakra-ui/react'
import { useFetchInvestors } from '../services/useFetchInvestors'
import { useStringsContext } from 'components/StringsContext'

export const Investors = () => {
  const { langKey } = useStringsContext()
  const { data } = useFetchInvestors()
  const isLoaded = !isEmpty(data)

  return (
    <Screen
      title={langKey?.users_investors}
    >
      <Stack spacing="20px">
        <Stack spacing="12px">
          <Heading fontSize="1.4rem">
            {langKey?.users_reward_share_title}
          </Heading>
          <RowsContainer hideDesign>
            {isLoaded ? (
              <>
                {data?.investors?.map(item => (
                  <Row.Ton
                    isLoaded={isLoaded}
                    username={item?.name}
                    value={item?.rewardShare * data?.last20Profit}
                  />
                ))}
              </>
            ) : (
              <Skeleton
                borderRadius="full"
                minH="30px"
                minW="120px"
              />
            )}
          </RowsContainer>
        </Stack>
        <Stack spacing="12px">
          <Heading fontSize="1.4rem">
            {langKey?.users_last_cycle_title}
          </Heading>
          <RowsContainer hideDesign>
            {isLoaded ? (
              <>
                {data?.investors?.map(item => (
                  <Row.Ton
                    isLoaded={isLoaded}
                    username={item?.name}
                    value={item?.rewardShare * data?.last1Profit}
                  />
                ))}
              </>
            ) : (
              <Skeleton
                borderRadius="full"
                minH="30px"
                minW="120px"
              />
            )}
          </RowsContainer>
        </Stack>
      </Stack>
    </Screen>
  )
}
