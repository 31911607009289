import { Route, Routes } from 'react-router-dom'
import { List } from './List'
import { Private } from './Private'
import { Public } from './Public'

export const Pools = () => {
  return (
    <Routes>
      <Route path="/*">
        <Route index element={<List/>} />
        <Route path="private/*" element={<Private/>} />
        <Route path="public/*" element={<Public/>} />
      </Route>
    </Routes>
  )
}
