import { Screen } from 'components/Screen'
import { useFetchTotals } from './services/useFetchTotals'
import { Flex, Heading, Image, Skeleton, Stack, Text } from '@chakra-ui/react'
import ImgPools from './images/pools.svg'
import ImgUsers from './images/users.svg'
import { Amount } from '../../components/Amount'
import { Link } from 'react-router-dom'
import { getTonDecimals } from 'utils/getTonValue'
import { isEmpty } from 'lodash';
import { useStringsContext } from 'components/StringsContext'

const Item = ({ title, isLoaded, value, label, image, decimals, to }) => {
  return (
    <Flex
      as={Link}
      to={to}
      bgGradient='linear(to-r, #7834FF, #36B7FF)'
      height="135px"
      justifyContent="space-between"
      alignItems="center"
      padding="20px"
      borderRadius="20px"
      color="white"
      cursor="pointer"
      transition="0.3s"
      _hover={{
        transform: 'scale(1.01)'
      }}
      _active={{
        transform: 'scale(0.99)'
      }}
    >
      <Flex flexDirection="column" justifyContent="space-between" height="100%">
        <Heading fontSize="1.2rem">
          {title}
        </Heading>
        <Stack spacing="6px" lineHeight="1">
          {!isLoaded ? (
            <Skeleton
              isLoaded={isLoaded}
              borderRadius="full"
              minH="30px"
              minW="120px"
              width="100%"
              height="100%"
            />
          ) : (
            <Amount decimals={decimals}>
              {value}
            </Amount>
          )}
          <Text fontSize="0.9rem">{label}</Text>
        </Stack>
      </Flex>
      <Image boxSize="80px" src={image}/>
    </Flex>
  )
}

export const Main = () => {
  const { langKey } = useStringsContext()
  const { data } = useFetchTotals()
  const isLoaded = !isEmpty(data)

  return (
    <Screen
      isHideBackButton
      title={langKey?.main_title}
    >
      <Stack spacing="18px">
        <Item
          isLoaded={isLoaded}
          to="pools"
          title={langKey?.main_pools_title}
          label={langKey?.main_pools_subtitle}
          value={data?.totalStaked}
          image={ImgPools}
          decimals={getTonDecimals(data?.totalStaked)}
        />
        <Item
          isLoaded={isLoaded}
          to="users"
          title={langKey?.main_users_title}
          label={langKey?.main_users_subtitle}
          value={data?.nominatorsCount}
          image={ImgUsers}
          decimals={0}
        />
      </Stack>
    </Screen>
  )
}
