import { Screen } from 'components/Screen'
import { useFetchPoolsItem } from '../../services/useFetchPoolsItem'
import { Stack, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { Row } from 'components/Row'
import { RowsContainer } from 'components/RowsContainer'
import { isEmpty } from 'lodash'
import { useStringsContext } from 'components/StringsContext'

export const ValidatorItem = () => {
  const { langKey } = useStringsContext()
  const { id, index } = useParams()
  const { data } = useFetchPoolsItem(id)
  const isLoaded = !isEmpty(data)

  const item = data?.investors[index]

  return (
    <Screen
      title={langKey?.langKey?.pools_item_public_validator_item_title}
    >
      <Stack spacing="20px">
        <Text
          color="#1398F5"
          fontSize="1.1rem"
          lineHeight="1"
        >
          {item?.name}
        </Text>
        <RowsContainer hideDesign>
          <Row.Text
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_validator_item_reward_share}
            value={`${item?.rewardShare * 100}%`}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_validator_item_clean_profit}
            value={item?.cleanProfit}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_validator_item_last_20_profit}
            value={item?.last20Profit}
          />
        </RowsContainer>
      </Stack>
    </Screen>
  )
}
