import { Screen } from 'components/Screen'
import { useFetchPoolsItem } from '../../services/useFetchPoolsItem'
import { RowsContainer } from 'components/RowsContainer'
import { Snippet } from 'components/Snippet/Snippet'
import { Stack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Row } from 'components/Row'
import { useStringsContext } from 'components/StringsContext'

export const PoolItem = () => {
  const { langKey } = useStringsContext()
  const { id } = useParams()
  const { data } = useFetchPoolsItem(id)
  const isLoaded = !isEmpty(data)

  const color = data?.pool?.isPublic ? '#8447FC' : '#1398F5'

  return (
    <Screen
      title={data?.pool?.title}
    >
      <Stack spacing="20px">
        <RowsContainer color={color}>
          <Row.Amount
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_nominators_count}
            value={data?.pool?.nominatorsCount}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_nominators_deposit}
            value={data?.pool?.nominatorsDeposit}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_nominators_withdraw}
            value={data?.pool?.nominatorsWithdraw}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_nominators_stake}
            value={data?.pool?.nominatorsStake}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_nominators_profit}
            value={data?.pool?.nominatorsProfit}
          />
        </RowsContainer>
        <Snippet.Full
          isLoaded={isLoaded}
          to="clients"
          label={langKey?.pools_item_public_clients_stake}
          callout={langKey?.pools_item_public_clients_title}
          amount={data?.clientsStake}
          color={color}
        />
        <Snippet.Full
          isLoaded={isLoaded}
          to="validator"
          label={langKey?.pools_item_public_validator_title}
          callout={langKey?.pools_item_public_validator_profit}
          amount={data?.validatorProfit}
          color={color}
        />
      </Stack>
    </Screen>
  )
}
