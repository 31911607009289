import { auth } from './auth'
import { totals } from './totals'
import { pools } from './pools'
import { users } from './users'
import { investors } from './investors'
import { strings } from './strings'

export const api = {
  auth,
  totals,
  pools,
  users,
  investors,
  strings
}
