import { Box, Center } from '@chakra-ui/react'

export const Alert = ({ children }) => {
  return (
    <Center flex={1} height="100vh" textAlign="center">
      <Box>
        {children}
      </Box>
    </Center>
  )
}
