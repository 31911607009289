import { IconButton } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { BiArrowBack } from 'react-icons/bi'

export const BackButton = ({ onClick, ...props }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (onClick) {
      onClick()
      return
    }
    navigate(-1)
  }

  return (
    <IconButton
      size="md"
      variant="ghost"
      aria-label="left"
      borderRadius="full"
      onClick={handleClick}
      icon={<BiArrowBack fontSize="1.3rem"/>}
      _hover={{
        backgroundColor: 'gray.200'
      }}
      {...props}
    />
  )
}
