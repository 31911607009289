import { createContext, useContext, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { Button, Center, Stack, Text } from '@chakra-ui/react'
import { api } from 'api'
import { useToast } from '../../hooks/useToast'
import { Loader } from '../Loader'

export const StringsContext = createContext({})

export const useStringsContext = (appId = 'default') => {
  const context = useContext(StringsContext)
  if (!context) throw new Error('useStringsContext must be used within StringsProvider!')

  const { langData, activeLang, setActiveLang } = context

  return {
    langKey: langData[appId]?.[activeLang],
    setActiveLang,
    activeLang
  }
}

const getLangKeys = (data, keys) => {
  let result = {}
  data.forEach((item, index) => {
    result[keys[index]] = {
      ru: item.list?.reduce((obj, item) => {
        const key = Object.keys(item)[0]
        return {
          ...obj,
          [item[key]]: item.value.ru ?? item[key]
        }
      }, {}),
      en: item.list?.reduce((obj, item) => {
        const key = Object.keys(item)[0]
        return {
          ...obj,
          [item[key]]: item.value.en ?? item[key]
        }
      }, {})
    }
  })
  return result
}

export const StringsProvider = ({ children }) => {
  const languageSystem = (window.navigator ? (window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage) : 'ru')?.slice(0, 2)?.toLowerCase()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [langData, setLangData] = useState(null)
  const [activeLang, setActiveLang] = useState(languageSystem ?? 'ru')

  const getData = async () => {
    try {
      setIsLoading(true)
      setIsError(false)
      const result = await Promise.all([
        api.strings.fetch({
          appVersionNumber: '1',
          appId: 'web'
        }),
      ])
      setLangData(getLangKeys(result, ['default', 'site']))
    } catch (e) {
      setIsError(true)
      toast({
        title: 'Ошибка',
        description: 'Не удалось загрузить локализацию',
        status: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  if (isLoading) {
    return (
      <Loader/>
    )
  }

  if (!isEmpty(langData)) {
    return (
      <StringsContext.Provider value={{ langData, activeLang, setActiveLang }}>
        {children}
      </StringsContext.Provider>
    )
  }

  if (isError && isEmpty(langData)) {
    return (
      <Center flex={1}>
        <Stack spacing="20px" alignItems="center">
          <Text>Не удалось загрузить локализацю</Text>
          <Button
            variant="bart"
            onClick={() => getData()}
            width="fit-content"
          >Повторить</Button>
        </Stack>
      </Center>
    )
  }

  return null
}
