import { useAuthCheck } from './services/useAuthCheck'
import { useEffect, useState } from 'react'
import { useAuthLogin } from './services/useAuthLogin'
import { Loader } from '../Loader'
import { Alert } from '../Alert'
import { Box, Text } from '@chakra-ui/react'

export const Authentication = ({ children }) => {
  const { authCheck } = useAuthCheck()
  const { authLogin } = useAuthLogin()
  const [isChecked, setIsChecked] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const startAuth = async () => {
      try {
        await authCheck()
        setIsChecked(true)
      } catch (e) {
        try {
          await authLogin()
          setIsChecked(true)
        } catch (e) {
          setError({
            code: e.code,
            message: e.message
          })
        }
      }
    }
    startAuth()
  }, [])

  if (error) {
    return (
      <Alert>
        <Text>{error.code}</Text>
        <Text>{error.message}</Text>
        <Box maxW="360px" fontSize="0.6rem">
          <Text>{JSON.stringify(window.Telegram?.WebApp, null, ' ')}</Text>
        </Box>
      </Alert>
    )
  }

  if (!isChecked) {
    return (
      <Loader/>
    )
  }

  return (
    <>
      {children}
    </>
  )
}
