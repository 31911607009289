import axios from 'axios'
import { baseURL } from 'constants/baseURL'

const entity = 'totals'

export const totals = Object.freeze({
  async fetch() {
    const response = await axios.get(`${baseURL}/${entity}`)
    return response.data
  },
})
