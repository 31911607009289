import { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useWindowHeight = () => {
  const location = useLocation()
  const [height, setHeight] = useState(window.innerHeight)

  const handleResize = () => {
    setTimeout(() => setHeight(window.innerHeight), 400)
  }

  useEffect(() => {
    handleResize()
  }, [location?.pathname])

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return height
}
