import { Route, Routes } from 'react-router-dom'
import { Totals } from './Totals'
import { Investors } from './Investors'
import { UserList } from './UserList'
import { UserListItem } from './UserListItem'


export const Users = () => {
  return (
    <Routes>
      <Route path="/*">
        <Route index element={<Totals/>} />
        <Route path="investors" element={<Investors/>} />
        <Route path="users-list/*">
          <Route index element={<UserList/>} />
          <Route path=":index" element={<UserListItem/>} />
        </Route>
      </Route>
    </Routes>
  )
}
