import { useWindowHeight } from 'hooks/useWindowHeight'
import { Flex } from '@chakra-ui/react'
import { Outlet, useLocation } from 'react-router-dom'
import { useLayoutEffect } from 'react'

export const Layout = ({ children }) => {
  const windowHeight = useWindowHeight()
  const location = useLocation()

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Flex
      position="relative"
      minW="360px"
      flex={1}
      backgroundColor="#eff1f3"
    >
      <Flex
        flexDirection="column"
        zIndex={1}
        flex={1}
        height={`${windowHeight}px`}
        width="100%"
      >
        <Flex
          position="relative"
          zIndex={2}
          ml="auto"
          mr="auto"
          maxW="490px"
          minW="320px"
          width="100%"
          flex={1}
          flexDirection="column"
        >
          {children ? children : <Outlet/>}
        </Flex>
      </Flex>
    </Flex>
  )
}
