import { Screen } from 'components/Screen'
import { useFetchPoolsItem } from '../../services/useFetchPoolsItem'
import { RowsContainer } from 'components/RowsContainer'
import { Stack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Row } from 'components/Row'
import { useStringsContext } from 'components/StringsContext'

export const Clients = () => {
  const { langKey } = useStringsContext()
  const { id } = useParams()
  const { data } = useFetchPoolsItem(id)
  const isLoaded = !isEmpty(data)

  return (
    <Screen
      title={langKey?.pools_item_public_clients_title}
    >
      <Stack spacing="20px">
        <RowsContainer hideDesign>
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_clients_stake}
            value={data?.clientsStake}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_clients_profit}
            value={data?.clientsProfit}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_clients_deposit}
            value={data?.clientsDeposit}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_item_public_clients_withdrawn}
            value={data?.clientsWithdraw}
          />
        </RowsContainer>
      </Stack>
    </Screen>
  )
}
