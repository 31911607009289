import { Screen } from 'components/Screen'
import { useFetchPools } from '../services/useFetchPools'
import { RowsContainer } from 'components/RowsContainer'
import { Stack } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { Snippet } from 'components/Snippet'
import { Row } from 'components/Row'
import { useStringsContext } from 'components/StringsContext'

export const List = () => {
  const { langKey } = useStringsContext()
  const { data } = useFetchPools()
  const isLoaded = !isEmpty(data)

  return (
    <Screen
      title={langKey?.pools_title}
    >
      <Stack spacing="20px">
        <RowsContainer>
          <Row.Amount
            isLoaded={isLoaded}
            label={langKey?.pools_nominators_count}
            value={data?.nominatorsCount}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_nominators_stake}
            value={data?.nominatorsStake}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_nominators_profit}
            value={data?.nominatorsProfit}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_nominators_deposit}
            value={data?.nominatorsDeposit}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.pools_nominators_withdraw}
            value={data?.nominatorsWithdraw}
          />
        </RowsContainer>
        {data?.pools?.map((item, index) => (
          <Snippet.Full
            key={index}
            isLoaded
            to={`${item?.isPublic ? 'public' : 'private'}/${item?.id}`}
            label={langKey?.pools_item_title}
            callout={item?.title}
            amount={item?.amount}
            color={item?.isPublic ? '#8447FC' : '#1398F5'}
          />
        ))}
      </Stack>
    </Screen>
  )
}
