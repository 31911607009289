import { Screen } from 'components/Screen'
import { Stack, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { Row } from 'components/Row'
import { RowsContainer } from 'components/RowsContainer'
import { isEmpty } from 'lodash'
import { useFetchUsers } from '../services/useFetchUsers'
import { useStringsContext } from 'components/StringsContext'

export const UserListItem = () => {
  const { langKey } = useStringsContext()
  const { index } = useParams()
  const { data } = useFetchUsers()
  const isLoaded = !isEmpty(data)

  const item = data && data[index]

  return (
    <Screen
      title={langKey?.users_list_item_title}
    >
      <Stack spacing="20px">
        <Text
          color="#1398F5"
          fontSize="1.1rem"
          lineHeight="1"
        >
          {item?.name}
        </Text>
        <RowsContainer hideDesign>
          <Row.Amount
            isLoaded={isLoaded}
            label={langKey?.users_list_item_stakes}
            value={item?.stakes}
          />
          <Row.Ton
            isLoaded={isLoaded}
            label={langKey?.users_list_item_revenue}
            value={item?.stake + item?.withdrawn - item?.deposit}
          />
        </RowsContainer>
      </Stack>
    </Screen>
  )
}
