import { Route, Routes } from 'react-router-dom'
import { PoolItem } from './PoolItem'
import { Clients } from './Clients'
import { Validator } from './Validator'
import { ValidatorItem } from './ValidatorItem'

export const Public = () => {
  return (
    <Routes>
      <Route path=":id/*">
        <Route index element={<PoolItem/>} />
        <Route path="clients" element={<Clients/>} />
        <Route path="validator/*">
          <Route index element={<Validator/>} />
          <Route path=":index/*">
            <Route index element={<ValidatorItem/>} />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}
