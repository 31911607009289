import { Box, Stack } from '@chakra-ui/react'

export const RowsContainer = ({ hideDesign, children, color = '#1398F5' }) => {
  return (
    <Box position="relative">
      {!hideDesign && (
        <Box
          position="absolute"
          top="20px"
          left={0}
          height="48px"
          width="5px"
          borderTopRightRadius="4px"
          borderBottomRightRadius="4px"
          backgroundColor={color}
        />
      )}
      <Stack
        bg="white"
        borderRadius="12px"
        padding="18px"
        spacing="18px"
      >
        {children}
      </Stack>
    </Box>
  )
}
