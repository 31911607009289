import { getFormatNumber } from '../../utils/getFormatNumber'
import { useEffect, useState } from 'react'
import { useSpring } from 'use-spring'
import { Box } from '@chakra-ui/react'

export const Amount = ({ children, decimals, ...props}) => {
  const [target, setTarget] = useState(0)
  const [current] = useSpring(target, {
    decimals,
    stiffness: 5900,
    mass: 0.5
  })

  useEffect(() => {
    setTarget(children)
  }, [children])

  return (
    <Box fontSize="1.9rem" fontWeight="extrabold" {...props}>
      {getFormatNumber(String(current), decimals)}
    </Box>
  )
}
