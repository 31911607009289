import { Full } from './Full'
import { Small } from './Small'
import { User } from './User'
import { Investor } from './Investor'

export const Snippet = {
  Full,
  Small,
  User,
  Investor
}
