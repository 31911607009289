import { useEffect } from 'react'
import { useToast } from 'hooks/useToast'
import useQuery from 'hooks/useQuery'
import { api } from 'api'

export const useFetchUsers = () => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    request,
    response
  } = useQuery(() => api.users.fetch())

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    request()
  }, [])

  return ({
    isLoadingFetch: isLoading,
    fetchUsers: request,
    data: response
  })
}
