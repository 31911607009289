import { Screen } from 'components/Screen'
import { useFetchUsersTotals } from '../services/useFetchUsersTotals'
import { Row } from 'components/Row'
import { RowsContainer } from 'components/RowsContainer'
import { isEmpty } from 'lodash'
import { Flex, Heading, Stack, Text } from '@chakra-ui/react'
import { ArrowIcon } from 'components/ArrowIcon'
import { Link } from 'react-router-dom'
import { useStringsContext } from 'components/StringsContext'

const Button = ({ children, to }) => {
  return (
    <Flex
      as={Link}
      to={to}
      height="64px"
      alignItems="center"
      justifyContent="space-between"
      padding="18px"
      cursor="pointer"
      color="white"
      fontWeight="700"
      fontSize="1.24rem"
      borderRadius="12px"
      bgGradient='linear(to-r, #7834FF, #36B7FF)'
      transition="0.3s"
      _hover={{
        transform: 'scale(1.01)'
      }}
      _active={{
        transform: 'scale(0.99)'
      }}
    >
      <Text>
        {children}
      </Text>
      <ArrowIcon color="white" fontSize="1rem"/>
    </Flex>
  )
}

export const Totals = () => {
  const { langKey } = useStringsContext()
  const { data } = useFetchUsersTotals()
  const isLoaded = !isEmpty(data)

  return (
    <Screen
      title={langKey?.users_title}
    >
      <Stack spacing="20px">
        <Button to="investors">
          {langKey?.users_investors}
        </Button>
        <Button to="users-list">
          {langKey?.users_list_title}
        </Button>
        <RowsContainer>
          <Row.Amount
            isLoaded={isLoaded}
            label={langKey?.users_public_nominators_count}
            value={data?.publicNominatorsCount}
          />
          <Row.Amount
            isLoaded={isLoaded}
            label={langKey?.users_last_30_days_nominators}
            value={data?.last30DaysNominators}
          />
          <Row.Amount
            isLoaded={isLoaded}
            label={langKey?.users_new_for_yesterday}
            value={data?.yesterdayNominators}
          />
        </RowsContainer>
        <Stack spacing="12px">
          <Heading fontSize="1.4rem">
            {langKey?.users_deposited_title}
          </Heading>
          <RowsContainer hideDesign>
            <Row.Ton
              isLoaded={isLoaded}
              label={langKey?.users_total_deposits}
              value={data?.totalDeposits}
            />
            <Row.Ton
              isLoaded={isLoaded}
              label={langKey?.users_last_30_days_deposits}
              value={data?.last30DaysDeposits}
            />
            <Row.Ton
              isLoaded={isLoaded}
              label={langKey?.users_yesterday_deposits}
              value={data?.yesterdayDeposits}
            />
          </RowsContainer>
        </Stack>
        <Stack spacing="12px">
          <Heading fontSize="1.4rem">
            {langKey?.users_clients_title}
          </Heading>
          <RowsContainer hideDesign>
            <Row.Ton
              isLoaded={isLoaded}
              label={langKey?.users_last_20_profit}
              value={data?.last20Profit}
            />
            <Row.Ton
              isLoaded={isLoaded}
              label={langKey?.users_last_1_profit}
              value={data?.last1Profit}
            />
          </RowsContainer>
          <RowsContainer hideDesign>
            <Row.Ton
              isLoaded={isLoaded}
              label={langKey?.users_withdrawn}
              value={data?.withdrawn}
            />
            <Row.Ton
              isLoaded={isLoaded}
              label={langKey?.users_withdraw_in_progress}
              value={data?.withdrawInProgress}
            />
          </RowsContainer>
        </Stack>
      </Stack>
    </Screen>
  )
}
